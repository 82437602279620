@import '../../shared.scss';

.btn {
    position: relative;
    padding: 1rem 2rem;
    background-color: $white;
    color: $base;
    font-weight: 600;
    border-radius: 30px;
    border: none;
    text-decoration: none;
    transition: all .2s ease-in-out;

    &:hover {
        background-color: $accent;
        color: $white;
    }

    &.accent {
        background-color: $accent;
        color: $white;

        &:hover {
            background-color: lighten($accent, 10);
        }
    }
}