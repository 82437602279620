@import '../../shared.scss';

.cake-container {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin:  0 auto 4rem;
    background-color: rgba($secondary, .2);
    border-radius: 30px;
    padding: 1.5rem;
}

.card-container {
    position: relative;

    @media screen and (min-width: 600px) {
        display: grid;
        grid-gap: 1.5rem;
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }
}