/* Fonts */

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;900&family=Sansita+Swashed:wght@600&display=swap');

/* Variables */

$base: #3B3B3B;
$white: #f7f7f7;
$primary: #FEDB70;
$secondary: #FF5645;
$accent: #2F24AF;

/* Utilities */

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.nav-spacer {
    margin-top: 100px;
}

.mb-1 {
    margin-bottom: 1rem;
}

.ml-1 {
    margin-left: 1rem;
}

.mt-auto {
    margin-top: auto;
}