@import '../../shared.scss';

.site-footer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 160px;
    background-color: darken($primary, 5);

    p {
        display: flex;
        align-items: center;
        font-size: 1.25rem;
        font-weight: 600;
        margin: 0;
    
        svg {
            width: 26px;
            height: 26px;
            fill: $secondary;
            margin-left: .75rem;
        }
    }
}