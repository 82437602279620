@import '../../shared.scss';

.cake-detail {
    position: relative;
    margin-bottom: 1.5rem;

    @media screen and (min-width: 768px) {
        display: flex;
    }

    &__image {
        position: relative;
        border-radius: 30px;
        overflow: hidden;
        margin-bottom: 1rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        @media screen and (min-width: 768px) {
            flex: 1 0 50%;
            margin-bottom: 0;
        }
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 30px;
        background-color: $white;
        padding: 1.5rem;

        @media screen and (min-width: 768px) {
            flex: 1 0 calc(50% - 1.5rem);
            margin-left: 1.5rem;
        }
    }
}

.rating {
    position: relative;
    display: grid;
    grid-template-columns: repeat(5, 30px);
    grid-gap: .5rem;

    &__item {
        position: relative;
        width: 30px;
        height: 30px;

        svg {
            width: 30px;
            height: 30px;
        }

        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }
}