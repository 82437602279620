@import '../../shared.scss';

.split-form {
    position: relative;
    
    @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
    }
}

.form-item {
    position: relative;
    display: block;
    margin-bottom: 1rem;

    label {
        display: block;
        font-weight: 600;
        margin-bottom: .5rem;
    }

    input[type=text],
    textarea {
        display: block;
        width: 100%;
        border-radius: .5rem;
        border: 1px solid rgba($base, .2);
        padding: .75rem .5rem;
    }
}

.form-error {
    font-size: .85rem;
    color: $secondary;
    margin-top: .25rem;
    background-color: rgba($primary, .15);
    border-radius: .5rem;
    padding: .5rem;
    border: 1px solid $secondary;
}