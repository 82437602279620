@import '../../shared.scss';

.icon-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: rgba($white, .35);
    border-radius: 50%;
    border: none;
    transition: all .3s ease-in-out;

    svg {
        width: 22px;
        height: 22px;
        fill: $base;
    }

    &:hover {
        background-color: rgba($secondary, .55);

        svg {
            fill: $white;
        }
    }
}