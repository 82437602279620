@import '../../shared.scss';

.modal-container {
    position: fixed;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    background-color: rgba($base, .35);
    transition: all .3s ease-in-out;

    &.is-open {
        opacity: 1;
        visibility: visible;
    }
}

.modal {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    bottom: -100%;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    max-width: 992px;
    max-height: calc(100vh - 3rem);
    border-radius: 30px;
    background-color: $white;
    overflow-x: hidden;
    overflow-y: auto;
    transition: all .3s ease-in-out;

    @media screen and (min-width: 768px) {
        max-height: calc(100vh - 5rem);
    }

    .modal-container.is-open & {
        bottom: 1rem;
        visibility: visible;
        opacity: 1;

        @media screen and (min-width: 768px) {
            bottom: 4rem;
        }
    }
    
    &__header {
        position: relative;
        display: flex;
        align-items: center;
        padding: 1rem;
        border-bottom: 1px solid rgba($base, .15);

        .icon-btn {
            margin-left: auto;
        }
    }

    &__content {
        padding: 1rem;
    }
}