@import '../../shared.scss';

.site-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    z-index: 10;
    transition: all .3s ease-in-out;

    &.is-solid {
        background-color: $white;
        box-shadow: 0 0 11px rgba($base, .2); 
    }

    &__inner {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 1rem;
    }

    &__nav {
        position: relative;
        margin-left: auto;

        &.is-open {
            .icon-btn {
                position: fixed;
                top: 1rem;
                right: 1rem;
                z-index: 10
            }
        }
    }
}

.site-logo {
    position: relative;
    font-family: 'Sansita Swashed', cursive;
    font-size: 2rem;
    color: $base;
    text-decoration: none;
    transition: font-size .2s ease-in-out;

    .site-header.is-solid & {
        font-size: 1.75rem;
    }

    @media screen and (min-width: 768px) {
        font-size: 3rem;

        .site-header.is-solid & {
            font-size: 2.5rem;
        }
    }
}

.site-menu {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100vh;
    max-width: 320px;
    padding: 1.5rem;
    list-style: none;
    margin: 0;
    overflow-y: auto;
    background-color: $white;
    box-shadow: 0 0 11px rgba($base, .2); 
    transition: all .3s ease-in-out;

    .site-header__nav.is-open & {
        right: 0;
    }

    li {
        display: block;
        margin: 1rem 0;

        a {
            position: relative;
            padding: .5rem;
            border-radius: 30px;
            text-decoration: none;
            color: $base;
            font-weight: 600;
            font-size: 1.5rem;
            transition: all .3s ease-in-out;
            
            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 0;
                background-color: $secondary;
                z-index: -1;
                transition: all .2s ease-in-out;
            }

            &:hover {
                &:before {
                    height: 50%;
                }
            }
        }
    }
}