@import '../../shared.scss';

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $white;
    padding: 1.5rem;
    margin-bottom: 1rem;
    border-radius: 30px;
    text-decoration: none;
    transition: all .3s ease-in-out;

    @media screen and (min-width: 600px) {
        margin-bottom: 0;
    }

    &:not(.card--addition):hover {
        box-shadow: 0 0 11px rgba($base, .2); 
    }

    &--addition {
        background-color: transparent;
        border: 3px dashed $secondary;
        width: 100%;

        &:hover {
            background-color: rgba($secondary, .2);
        }
    }

    &__image {
        position: relative;
        display: block;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        overflow: hidden;
        margin-bottom: 1rem;
        background-color: rgba($primary, .4);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        svg {
            width: 60px;
            height: 60px;
            fill: $secondary;
        }

        .card--addition & {
            width: 60px;
            height: 60px;
            border-radius: none;
        }
    }

    &__title {
        color: $base;
        font-size: 1.25rem;
        font-weight: 600;

        .card--addition & {
            color: $secondary;
        }
    }
}