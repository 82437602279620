@import '../../shared.scss';

.hero-panel {
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: 600px;
    display: flex;    

    &__content {
        display: flex;
        flex-direction: column;
        flex: 1 0 50%;
        align-items: center;
        justify-content: center;
        max-width: 1200px;
        padding: 0 1rem;
        margin: 0 auto;
        text-align: center;
        z-index: 1;

        h1 {
            font-family: 'Lato', sans-serif;
            font-size: 2.5rem;
        }

        .btn {
            margin-top: 1.5rem;
        }
    }
}